import GameState from '@gamepark/grandbois/GameState'
import {setupPlayers} from '@gamepark/grandbois/Grandbois'
import {tiles} from '@gamepark/grandbois/material/Tiles'
import TowerColor from '@gamepark/grandbois/material/TowerColor'
import Move from '@gamepark/grandbois/moves/Move'
import {TutorialDescription} from '@gamepark/react-client'
import shuffle from 'lodash.shuffle'

const initialTiles = [0, 31, 36, 30, 32]
const lastDeckTiles = [25, 13, 20, 22, 1, 33, 5]

const GrandboisTutorial: TutorialDescription<GameState, Move, TowerColor> = {
  setupTutorial: () => {
    const startDeck = [...initialTiles, ...shuffle(Array.from(tiles.keys()).filter(tile => !initialTiles.includes(tile) && !lastDeckTiles.includes(tile))), ...lastDeckTiles]
    const tileStart = startDeck.splice(0, 1)[0]
    const river = startDeck.splice(0, 4)
    const players = setupPlayers([{id: TowerColor.Blue}, {id: TowerColor.Black}, {id: TowerColor.White}])
    return [{
      players,
      deck: startDeck,
      river,
      forest: [{tile: tileStart, x: 0, y: 0, rotation: 0}],
      activePlayer: players[0].tower,
      over: false
    }, [TowerColor.Blue, TowerColor.Black, TowerColor.White]]
  },

  expectedMoves: () => [
    // Automatic Tutorial Draft Phase
  ]
}

export default GrandboisTutorial

export function resetTutorial() {
  localStorage.removeItem('grandbois')
  window.location.reload()
}
