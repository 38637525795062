import {FC, useEffect, useState} from 'react'
import {useAnimation, useSound} from '@gamepark/react-client'
import {Sounds} from './Sounds'
import {AudioLoader} from '../util/AudioLoader'
import {useSelector} from 'react-redux'
import GamePageState from '@gamepark/react-client/dist/Types/GamePageState'
import RevealNewRiverTile from '@gamepark/grandbois/moves/RevealNewRiverTile'
import MoveType from '@gamepark/grandbois/moves/MoveType'
import PlaceForestTile from '@gamepark/grandbois/moves/PlaceForestTile'
import PlaceTower from '@gamepark/grandbois/moves/PlaceTower'

type GrandboisSoundsProps = {
  audioLoader: AudioLoader
}

const GrandboisSounds: FC<GrandboisSoundsProps> = ({  audioLoader }) => {
  // Creating this sound only to show the button in the menu
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useSound(Sounds.ambianceForest);
  const ambianceForest = Sounds.ambianceForest;
  const newTile = Sounds.newTile;
  const placeTile = Sounds.placeTile;
  const placeTower = Sounds.placeTower;
  const [ambianceEnabled, setAmbianceEnabled] = useState(false);
  const [ambianceFail, setAmbianceFail] = useState(false);
  const newTileAnimation = useAnimation<RevealNewRiverTile>(animation => animation.move.type === MoveType.RevealNewRiverTile);
  const placeTileAnimation = useAnimation<PlaceForestTile>(animation => animation.move.type === MoveType.PlaceForestTile);
  const placeTowerAnimation = useAnimation<PlaceTower>(animation => animation.move.type === MoveType.PlaceTower);
  const muted = useSelector((state: GamePageState) => state.soundsMuted)

  useEffect(() => {
    if (!muted) {
      audioLoader.unmute()
    } else {
      audioLoader.mute();
    }
  // eslint-disable-next-line
  }, [muted])

  useEffect(() => {
    // If the user hasn't click on the page before the audio context is loaded, the ambiance sound won't be run.
    // Then we add an event on the document to enable the ambiance only if it has failed.
    if (audioLoader.status() === 'suspended') {
      setAmbianceFail(true)
    } else {
      audioLoader.loop(ambianceForest);
    }
  // eslint-disable-next-line
  }, [ambianceForest])

  useEffect(() => {
    const enableAmbiance = () => {
      audioLoader.loop(ambianceForest);
      setAmbianceEnabled(true);
    }

    if (ambianceFail && !ambianceEnabled) {
        document.addEventListener('click', enableAmbiance)
    }

    return () => {
      document.removeEventListener('click', enableAmbiance)
    }
  // eslint-disable-next-line
  }, [ambianceFail, ambianceEnabled])

  useEffect(() => {
    if (newTileAnimation) {
      audioLoader.play(newTile, false, 1);
    }
  // eslint-disable-next-line
  }, [newTileAnimation?.move]);

  useEffect(() => {
    if (placeTileAnimation?.move) {
      audioLoader.play(placeTile, false, 1);
    }
  // eslint-disable-next-line
  }, [placeTileAnimation?.move]);

  useEffect(() => {
    if (placeTowerAnimation) {
      audioLoader.play(placeTower, false, 1);
    }
  // eslint-disable-next-line
  }, [placeTowerAnimation?.move]);

  return null;
}

export { GrandboisSounds }