import GameView from '@gamepark/grandbois/GameView'
import {changeActivePlayer} from '@gamepark/grandbois/moves/ChangeActivePlayer'
import {MoveView} from '@gamepark/grandbois/moves/Move'
import MoveType from '@gamepark/grandbois/moves/MoveType'
import {placeForestTile} from '@gamepark/grandbois/moves/PlaceForestTile'
import {placeTower} from '@gamepark/grandbois/moves/PlaceTower'
import {revealClansInView} from '@gamepark/grandbois/moves/RevealClans'
import {revealNewRiverTileInView} from '@gamepark/grandbois/moves/RevealNewRiverTile'
import {Rules} from '@gamepark/rules-api'
import MoveForest, {MOVE_FOREST, moveForest} from './moves/MoveForest'
import TowerColor from '@gamepark/grandbois/material/TowerColor'

type LocalMove = MoveView | MoveForest

export default class GrandboisView extends Rules<GameView, LocalMove, TowerColor> {
  getActivePlayer() {
    return this.isOver() ? undefined : this.state.activePlayer
  }

  play(move: LocalMove): MoveView[] {
    switch (move.type) {
      case MoveType.PlaceForestTile:
        placeForestTile(this.state, move)
        break
      case MoveType.RevealNewRiverTile:
        revealNewRiverTileInView(this.state, move)
        break
      case MoveType.ChangeActivePlayer:
        changeActivePlayer(this.state)
        break
      case MoveType.PlaceTower:
        placeTower(this.state)
        break
      case MoveType.RevealClans:
        revealClansInView(this.state, move)
        break
      case MOVE_FOREST:
        moveForest(this.state, move)
        break
    }
    return []
  }
}
