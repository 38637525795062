import {Animations} from '@gamepark/react-client'
import GameView from '@gamepark/grandbois/GameView'
import {MoveView} from '@gamepark/grandbois/moves/Move'
import TowerColor from '@gamepark/grandbois/material/TowerColor'
import MoveType from '@gamepark/grandbois/moves/MoveType'
import AnimationContext from '@gamepark/react-client/dist/animations/AnimationContext'

export default class GrandboisAnimations extends Animations<GameView, MoveView, TowerColor> {
  override getPreDuration(move: MoveView, {action, state, playerId}: AnimationContext<GameView, MoveView, TowerColor>) {
    switch (move.type) {
      case MoveType.PlaceForestTile: {
        return state.activePlayer === playerId ? 0 : 1
      }
      case MoveType.PlaceTower: {
        return state.activePlayer === playerId ? 0 : 1
      }
      case MoveType.RevealNewRiverTile: {
        return 1
      }
      default :
        return 0
    }
  }
}
