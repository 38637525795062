/** @jsxImportSource @emotion/react */
import {css, keyframes, Theme, useTheme} from '@emotion/react'
import {getForestView, getIllegalTileMessage, isLegalTilePosition} from '@gamepark/grandbois/ForestView'
import GameView from '@gamepark/grandbois/GameView'
import {activePlayerCouldPlaceTower} from '@gamepark/grandbois/Grandbois'
import PlacedTile, {getPlacedTileSpaceXY, isPlacedTile} from '@gamepark/grandbois/material/PlacedTile'
import {Clearing} from '@gamepark/grandbois/material/Tile'
import {tiles} from '@gamepark/grandbois/material/Tiles'
import TowerColor from '@gamepark/grandbois/material/TowerColor'
import ChangeActivePlayer from '@gamepark/grandbois/moves/ChangeActivePlayer'
import MoveType from '@gamepark/grandbois/moves/MoveType'
import PlaceForestTile from '@gamepark/grandbois/moves/PlaceForestTile'
import PlaceTower from '@gamepark/grandbois/moves/PlaceTower'
import {RevealNewRiverTileView} from '@gamepark/grandbois/moves/RevealNewRiverTile'
import Player from '@gamepark/grandbois/Player'
import PlayerView from '@gamepark/grandbois/PlayerView'
import {useAnimation, usePlay, usePlayerId} from '@gamepark/react-client'
import {Draggable} from '@gamepark/react-components'
import {FC, MouseEvent, useEffect, useState} from 'react'
import {useDrop, XYCoord} from 'react-dnd'
import {useTranslation} from 'react-i18next'
import {towerImage} from '../clans/TowerInfo'
import DraggedTile, {draggedTile} from '../drag-objects/DraggedTile'
import Images from '../material/Images'
import {Sounds} from '../sounds/Sounds'
import {AudioLoader} from '../util/AudioLoader'
import Button from '../util/Button'
import {
  cardHeight, cardStyle, cardWidth, placedCardX, placedCardY, riverAreaHeight, riverAreaLeft, riverAreaTop, riverAreaWidth, riverLeft, riverTop, spaceHeight,
  spaceWidth, topMargin, towerTopY
} from '../util/Styles'
import {drawPileCardX, drawPileCardY} from './DrawPile'
import {convertIntoPercent, initialForestPosition} from './Forest'
import TileCard from './TileCard'

type Props = {
  game: GameView
  player?: Player | PlayerView
  audioLoader: AudioLoader
}

const River: FC<Props> = ({game,player, audioLoader}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const playerId = usePlayerId<TowerColor>()
  const play = usePlay<PlaceForestTile | PlaceTower | ChangeActivePlayer>()
  const [playingTile, setPlayingTile] = useState<PlacedTile>()
  const tilePlayed = playingTile?.tile ?? game.tilePlayed
  const [hideTowerPosition, setHideTowerPosition] = useState<boolean>(false)
  useEffect(() => {
    if (playingTile) setHideTowerPosition(false)
  }, [playingTile])
  const [tilesRotation, setTilesRotation] = useState<{[key in number]?:number}>({})
  useEffect(() => {
    if (playingTile && !game.river.some(tile => tile === playingTile.tile))
      setPlayingTile(undefined)
  }, [game, playingTile])
  const rotate = (event: MouseEvent<HTMLDivElement>, tile: number) => {
    event.stopPropagation()
    if (playingTile && playingTile.tile === tile) {
      setPlayingTile({...playingTile, rotation: (playingTile.rotation + 1) })
    }
    const rotation = tilesRotation[tile]
    setTilesRotation({...tilesRotation,[tile]:rotation ? rotation + 1 : 1} )
  }
  const forestCenter = game.forestCenter ?? initialForestPosition
  const deltaPercent = convertIntoPercent(forestCenter)
  const forestView = getForestView(game)
  const isLegalTile = game.tilePlayed !== undefined || (playingTile && isLegalTilePosition(forestView, playingTile))
  const towerPotentialPosition = (playerId && playerId === game.activePlayer && playingTile && isLegalTile
                                  && ( game.players.length === 2 ? player!.towersPosition.length <= 1 : player!.towersPosition.length === 0 ))
                                  ? getTowerPotentialPosition(playingTile) : null
  const [, ref] = useDrop({
    accept: 'Tile',
    canDrop: (item: DraggedTile) => isPlacedTile(item),
    drop: () => undefined
  })
  const placeTileAndTower = (playingTile: PlacedTile) => {
    play({type: MoveType.PlaceForestTile, placedTile: {...playingTile,rotation:playingTile.rotation % 4}})
    play({type: MoveType.PlaceTower})
  }
  const placeTileAndEndTurn = (playingTile: PlacedTile) => {
    play({type: MoveType.PlaceForestTile, placedTile: {...playingTile,rotation:playingTile.rotation % 4}})
    play({type: MoveType.ChangeActivePlayer})
  }
  const placeTile = (playingTile: PlacedTile) => {
    play({type: MoveType.PlaceForestTile, placedTile: {...playingTile,rotation:playingTile.rotation % 4}})
  }
  const placeTileAnimation = useAnimation<PlaceForestTile>(animation => animation.move.type === MoveType.PlaceForestTile)
  const drawAnimation = useAnimation<RevealNewRiverTileView>(animation => animation.move.type === MoveType.RevealNewRiverTile)
  return <>
    {
      game.river.map((tile, index) => {
         if (!tile) return null
         const rotation = tilesRotation[tile] ?? 0
         const item = playingTile && playingTile.tile === tile ? playingTile : {tile, rotation}
         return <Draggable key={tile} type={draggedTile} item={item}
                            drop={setPlayingTile}
                            canDrag={game.activePlayer === playerId}
                            animation={{properties: ['transform', 'left', 'top'], seconds: 0.2}}
                            css={[cardStyle,
                              placeTileAnimation && placeTileAnimation.move.placedTile.tile === tile ? placingTileAnimation(placeTileAnimation.move.placedTile, deltaPercent, placeTileAnimation.duration) :
                              playingTile && playingTile.tile === tile ? playingTileStyle(playingTile, deltaPercent, theme) : riverTileStyle(index, theme)
                            ]}
                            onClick={event => { rotate(event, tile); audioLoader.play(Sounds.rotateTile, false, 0.5);}}
                  >
            <TileCard tile={tiles[tile]} css={[ placeTileAnimation && placeTileAnimation.move.placedTile.tile === tile ?
                                                              rotatedStyle(rotation - rotation%4 + placeTileAnimation.move.placedTile.rotation, placeTileAnimation.duration)
                                                            : rotatedStyle(rotation),
                                                            ( playingTile && playingTile.tile === tile ) ? highlightStyle(isLegalTile!) : ''
                                                          ]}
                      />
          </Draggable>
        }
      )
    }
    {
      playerId && playerId === game.activePlayer && tilePlayed && isLegalTile
      &&
        <>
        {
          activePlayerCouldPlaceTower(game, tilePlayed)
            ?
            <div css={[buttonPanelStyle,towerChoicePosition]}>
              <p css={questionStyle}>{t('Would you like to place your Watchtower here?')}</p>
              <div css={towerQuestionStyle(player!.tower)} onClick={() => playingTile ? placeTileAndTower(playingTile) : play({type: MoveType.PlaceTower})}/>
              <Button css={validateStyle} onClick={() => playingTile ? placeTileAndTower(playingTile) : play({type: MoveType.PlaceTower})}>
                {t('Place my Watchtower and validate')}
              </Button>
              <Button css={validateStyle} onClick={() => playingTile ? placeTileAndEndTurn(playingTile) : play({type: MoveType.ChangeActivePlayer})}
                      onMouseEnter={() => setHideTowerPosition(true)}
                      onMouseLeave={() => setHideTowerPosition(false)}
              >{t('Validate without placing my Watchtower')}</Button>
            </div>
            :
            <div css={[buttonPanelStyle,validateChoicePosition]}>
            <p css={questionStyle}>{t('Would you like to place your tile here?')}</p>
            <Button css={validateStyle} onClick={() => placeTile(playingTile!)}>{t('Validate')}</Button>
            </div>
        }
        </>
    }
    {
      playerId && playerId === game.activePlayer && playingTile && !isLegalTile
      &&
            <div css={[buttonPanelStyle,illegalChoicePosition]}>
              <p css={illegalStyle}>{getIllegalTileMessage(forestView, playingTile, t)}</p>
            </div>
    }
    {
      towerPotentialPosition !== null && !hideTowerPosition &&
      <div css={towerStyle(playerId!, towerPotentialPosition, deltaPercent)} onClick={event => { rotate(event, playingTile!.tile); audioLoader.play(Sounds.rotateTile, false, 0.5); }}/>
    }
    {
      drawAnimation && game.deck &&
      <TileCard tile={tiles[drawAnimation.move.tile]} reverseBack={true} css={[cardStyle,drawTileStyle(game.deck,game.river.indexOf(null),drawAnimation.duration)]} />
    }
    <div ref={ref} css={riverAreaStyle}/>
  </>
}

const riverAreaStyle = css`
  z-index: 1;
  position: absolute;
  left: ${riverAreaLeft}%;
  top: ${riverAreaTop}%;
  width: ${riverAreaWidth}%;
  height: ${riverAreaHeight}%;
  background-image: url(${Images.woodTexture});
  background-position: center center;
  background-repeat: repeat;
  background-size: cover;
  border-radius: 1em;
  border: solid 0.1em saddlebrown;
  box-shadow: 0 0 1em #000;
`

const playingTileStyle = ({x,y}: XYCoord, {x:deltaX,y:deltaY}: XYCoord, theme: Theme) => css`
  left: ${placedCardX(x, deltaX)}%;
  top: ${placedCardY(y, deltaY)}%;
  z-index: 2;
  ${rotationArrowStyle(theme)}
`
const riverTileStyle = (index: number, theme: Theme) => css`
  top: ${riverCardY(index)}%;
  left: ${riverLeft}%;
  z-index: 2;
  &:hover {
    ${rotationArrowStyle(theme)}
  }
`
const placingTileAnimation = ({x,y}: XYCoord, {x:deltaX,y:deltaY}: XYCoord, duration: number) => css`
  left: ${placedCardX(x, deltaX)}%;
  top: ${placedCardY(y, deltaY)}%;
  z-index: 2;
  transition: left ${duration}s ease-in-out, top ${duration}s ease-in-out;
`
const rotatedStyle = (rotation: number, duration: number = 0.2) => css`
  transform: rotate(${90 * rotation}deg);
  transition: transform ${duration}s ease-in-out;
`

const highlightStyle = (highlight: boolean) => highlight ? css`
  border: 0.2em solid greenyellow;
  box-shadow: 0.2em 0.2em 1em greenyellow;
` : css`
  border: 0.2em solid darkred;
  box-shadow: 0.2em 0.2em 1em darkred;
`
const rotationArrowStyle = (theme: Theme) => css`
  &:before {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    left: 85%;
    top: 20%;
    background-image: url(${theme.light ? Images.rotate : Images.rotateDark});
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(-60deg);
  }
`
const drawTileStyle = (nbDeck:number,index:number, duration:number) => {
  const xFrom = drawPileCardX(nbDeck - 1) * 100 / cardWidth
  const yFrom = drawPileCardY(nbDeck - 1) * 100 / cardHeight
  const xTo = riverLeft * 100 / cardWidth
  const yTo = riverCardY(index) * 100 / cardHeight
  const keyframe = keyframes`
    from {
      transform: translate(${xFrom}%, ${yFrom}%) translateZ(30em) rotateX(180deg);
    }
    to {
      transform: translate(${xTo}%, ${yTo}%) translateZ(30em);
    }
   `
  return css`
     animation: ${keyframe} ${duration}s ease-in-out forwards;
    z-index: 3;
   `
}

const buttonPanelStyle = css`
  position: absolute;
  font-size: 2.5em;
  padding: 0.5em;
  z-index: 5;
  background-image: url(${Images.woodTexture});
  background-position: center center;
  background-repeat: repeat;
  background-size: cover;
  border-radius: 0.5em;
  border: solid 0.1em #8b4513;
  box-shadow: 0 0 1em #000;
  text-align: center;
  color: #fff381;
`

const towerChoicePosition = css`
  right: 30%;
  bottom: 1%;
  width: 40%;
`

const validateChoicePosition = css`
  right: 40%;
  bottom: 1%;
  width: 20%;
`

const illegalChoicePosition = css`
  right: 32.5%;
  bottom: 1%;
  width: 35%;
`

const towerStyle = (tower: TowerColor, {x,y}:XYCoord, {x:deltaX,y:deltaY}:XYCoord) => css`
  position: absolute;
  left: ${placedCardX(x, deltaX) - 0.5}%;
  top: ${placedCardY(y, deltaY) - towerTopY}%;
  width: ${spaceWidth + 1}%;
  height: ${spaceHeight + towerTopY}%;
  z-index: 3;
  background-image: url(${towerImage[tower]});
  filter: drop-shadow(0.1em 0.1em 0.4em white);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  animation: ${pulse} 0.5s linear alternate infinite;
  opacity:0.7;
  pointer-events: none;
  transition: left 0.2s ease-in-out, top 0.2s ease-in-out;
  &:after{
    content:"?";
    position: relative;
    font-size:4em;
    color:#111;
    margin-left: 65%;
    margin-top: 40%;
    display: inline-block;
    filter: drop-shadow(0.1em 0.1em 0.3em white);
  }
`
const pulse = keyframes`
  to {
    transform: translate(0%, -10%) scale(1.1);
  }
`

export function getTowerPotentialPosition(playingTile: PlacedTile | undefined) {
  if (playingTile !== undefined) {
    const clearingIndex = tiles[playingTile.tile].findIndex(space => space === Clearing)
    if(clearingIndex >= 0)
        return getPlacedTileSpaceXY(playingTile, clearingIndex)
    else
      return null
  } else
    return null
}

const questionStyle = css`
  margin : 0 0 2% 0;
`
const validateStyle = css`
  font-size : 90%;
  margin : 2%;
  max-width: 40%;
`
const illegalStyle = css`
  margin : 0;
`

const towerQuestionStyle = (tower: TowerColor) => css`
  position: absolute;
  left: 0%;
  bottom: 0%;
  width: 15%;
  height: 80%;
  z-index: 3;
  background-image: url(${towerImage[tower]});
  filter: drop-shadow(0.1em 0.1em 0.4em black);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  cursor:pointer;
`

export const riverCardY = (index: number) => riverTop + (cardHeight + topMargin) * index

export const riverTileTop = (game: GameView, tile: number) => riverCardY(game.river.indexOf(tile))

export default River