import cardClanBack from './cards/clan-card-back.jpg'
import cardFoxes from './cards/clan-card-foxes.jpg'
import cardLizards from './cards/clan-card-lizards.jpg'
import cardRabbits from './cards/clan-card-rabbits.jpg'
import cardRaccoons from './cards/clan-card-raccoons.jpg'
import cardToads from './cards/clan-card-toads.jpg'
import iconFoxOthers from './icon-fox-others.png'
import iconFox from './icon-fox.png'
import iconFoxesTower from './icon-foxes-tower.png'
import iconFoxes from './icon-foxes.png'
import iconLizardOthers from './icon-lizard-others.png'
import iconLizard from './icon-lizard.png'
import iconLizardsTower from './icon-lizards-tower.png'
import iconLizards from './icon-lizards.png'
import iconRabbitOthers from './icon-rabbit-others.png'
import iconRabbit from './icon-rabbit.png'
import iconRabbitsTower from './icon-rabbits-tower.png'
import iconRabbits from './icon-rabbits.png'
import iconRaccoonOthers from './icon-raccoon-others.png'
import iconRaccoon from './icon-raccoon.png'
import iconRaccoonsTower from './icon-raccoons-tower.png'
import iconRaccoons from './icon-raccoons.png'
import iconToadOthers from './icon-toad-others.png'
import iconToad from './icon-toad.png'
import iconToadsTower from './icon-toads-tower.png'
import iconToads from './icon-toads.png'
import arrowOrange from './menus/arrow-orange.png'
import arrowWhite from './menus/arrow-white.png'
import forestGround from './menus/forest-ground.jpg'
import buttonBlack from './menus/menu-black.png'
import buttonBlue from './menus/menu-blue.png'
import buttonYellow from './menus/menu-gold.png'
import buttonGreen from './menus/menu-green.png'
import buttonGrey from './menus/menu-grey.png'
import buttonRed from './menus/menu-red.png'
import textureGrey from './menus/texture-grey.jpg'
import woodTexture from './menus/wood-texture.jpg'
import rotateDark from './rotate-dark.png'
import rotate from './rotate.png'
import scoreIcon from './score-icon.png'
import tile1 from './tiles/tile-1.jpg'
import tile10 from './tiles/tile-10.jpg'
import tile11 from './tiles/tile-11.jpg'
import tile12 from './tiles/tile-12.jpg'
import tile13 from './tiles/tile-13.jpg'
import tile14 from './tiles/tile-14.jpg'
import tile15 from './tiles/tile-15.jpg'
import tile16 from './tiles/tile-16.jpg'
import tile17 from './tiles/tile-17.jpg'
import tile18 from './tiles/tile-18.jpg'
import tile19 from './tiles/tile-19.jpg'
import tile2 from './tiles/tile-2.jpg'
import tile20 from './tiles/tile-20.jpg'
import tile21 from './tiles/tile-21.jpg'
import tile22 from './tiles/tile-22.jpg'
import tile23 from './tiles/tile-23.jpg'
import tile24 from './tiles/tile-24.jpg'
import tile25 from './tiles/tile-25.jpg'
import tile26 from './tiles/tile-26.jpg'
import tile27 from './tiles/tile-27.jpg'
import tile28 from './tiles/tile-28.jpg'
import tile29 from './tiles/tile-29.jpg'
import tile3 from './tiles/tile-3.jpg'
import tile30 from './tiles/tile-30.jpg'
import tile31 from './tiles/tile-31.jpg'
import tile32 from './tiles/tile-32.jpg'
import tile33 from './tiles/tile-33.jpg'
import tile34 from './tiles/tile-34.jpg'
import tile35 from './tiles/tile-35.jpg'
import tile36 from './tiles/tile-36.jpg'
import tile4 from './tiles/tile-4.jpg'
import tile5 from './tiles/tile-5.jpg'
import tile6 from './tiles/tile-6.jpg'
import tile7 from './tiles/tile-7.jpg'
import tile8 from './tiles/tile-8.jpg'
import tile9 from './tiles/tile-9.jpg'
import tileBack from './tiles/tile-back.jpg'
import tileStart from './tiles/tile-start.jpg'
import blackTower from './towers/tower-black.png'
import blueTower from './towers/tower-blue.png'
import brownTower from './towers/tower-brown.png'
import whiteTower from './towers/tower-white.png'

const Images = {
  buttonBlack, buttonBlue, buttonGreen, buttonGrey, buttonRed, buttonYellow, textureGrey,
  tileBack, tileStart,
  tile1, tile2, tile3, tile4, tile5, tile6, tile7, tile8, tile9, tile10,
  tile11, tile12, tile13, tile14, tile15, tile16, tile17, tile18, tile19, tile20,
  tile21, tile22, tile23, tile24, tile25, tile26, tile27, tile28, tile29, tile30,
  tile31, tile32, tile33, tile34, tile35, tile36,
  cardToads, cardRabbits, cardFoxes, cardRaccoons, cardLizards, cardClanBack,
  brownTower, blackTower, blueTower, whiteTower,
  rotate,rotateDark,
  forestGround, woodTexture, arrowOrange, arrowWhite, scoreIcon,
  iconFox,iconLizard,iconRabbit,iconRaccoon,iconToad,
  iconFoxes,iconLizards,iconRabbits,iconRaccoons,iconToads,
  iconFoxesTower,iconLizardsTower,iconRabbitsTower,iconRaccoonsTower,iconToadsTower,
  iconFoxOthers,iconLizardOthers,iconRabbitOthers,iconRaccoonOthers,iconToadOthers
}

export default Images