import {css} from '@emotion/react'
import {FC, HTMLAttributes} from 'react'
import Images from '../material/Images'
import {Avatar} from '@gamepark/react-client'
import {SpeechBubbleDirection} from '@gamepark/react-client/dist/Avatar'
import TowerColor from '@gamepark/grandbois/material/TowerColor'
import Player from '@gamepark/grandbois/Player'
import PlayerView from '@gamepark/grandbois/PlayerView'

type GrandboisAvatarProps = {
    player: Player | PlayerView
    playerInfo?: any,
    speechBubbleDirection?: SpeechBubbleDirection
} & Omit<HTMLAttributes<HTMLElement>, 'color'>

const GrandboisAvatar: FC<GrandboisAvatarProps> = ({player, playerInfo, speechBubbleDirection, ...props}) => {

    return <Avatar {...props} css={avatarCss} playerId={player.tower} speechBubbleProps={{direction: speechBubbleDirection}}/>
}

const avatarCss = css`
  font-family: 'News Cycle', sans-serif;
  color: black;
  > p {
    filter: drop-shadow(0.1em 0.1em black);
  }
`

const playerDefaultImages = new Map<TowerColor, any>();
playerDefaultImages.set(TowerColor.Blue, Images.blueTower);
playerDefaultImages.set(TowerColor.Black, Images.blackTower);
playerDefaultImages.set(TowerColor.Brown, Images.brownTower);
playerDefaultImages.set(TowerColor.White, Images.whiteTower);

export default GrandboisAvatar