import ambianceForest from './ambiance-forest.mp3'
import ambianceForest1 from './ambiance-forest-1.mp3'
import ambianceForest2 from './ambiance-forest-2.mp3'
import newTile from './new-tile.mp3'
import placeTile from './place-tile.mp3'
import placeTower from './place-tower.mp3'
import rotateTile from './rotate-tile.mp3'
import suspense from './suspense.mp3'

const Sounds = {
  ambianceForest,
  ambianceForest1,
  ambianceForest2,
  newTile,
  placeTile,
  placeTower,
  rotateTile,
  suspense
}

export { Sounds }